<template>
  <div class="steps d-flex XXflex-wrap XXflex-sm-nowrap justify-content-between">
    
    <template v-for="step in steps">
      <div class="step w-100" :class="{completed: isStepComplete(step.id)}" :key="`step-${step.id}`">
        <div class="step-icon-wrapper" v-b-tooltip.click="`${step.label}`">
          <div class="step-icon">
            <b-icon 
              :icon="step.icon"
              aria-hidden="true"
              class=""
            ></b-icon>
          </div>
        </div>
        <h5 class="step-title text-muted">{{ step.label }}</h5>
      </div>
    </template>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'JobSteps',

  components: {
  },

  props: [
    'status', // Job status
  ],

  data() {
    return {
      steps: [
        {'id': 'incoming', 'icon': 'eye', 'label': 'Confirmed', 'at_status': 1},
        {'id': 'quality', 'icon': 'search', 'label': 'Quality Check', 'at_status': 10},
        {'id': 'processing', 'icon': 'printer', 'label': 'Processing', 'at_status': 11},
        {'id': 'shipped', 'icon': 'truck', 'label': 'Fulfilled', 'at_status': 100},
      ],
    }
  },

  computed: {
    ...mapGetters('job', [
      'printerapiStatusInfo'
    ]),
  },

  methods: {
    // Hardcoded steps progress
    isStepComplete(step_id) {
      const step = this.steps.find( x => x.id === step_id );
      
      if (!step) {
        return false;
      }

      return step.at_status <= this.status;
    }
  },
}
</script>

<style lang="scss" scoped>
  .step {
    display: block;
    width: 100%;
    text-align: center;
  }
  
  .step-title {
    display: none;
  }

  .step-icon-wrapper {
    display: block;
    position: relative;
    width: 100%;
    margin-bottom: 0.5em;
    text-align: center;
  }

  .step-icon {
    display: inline-block;
    position: relative;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    font-size: 28px;
    line-height: 49px;
    z-index: 5;
    border-color: #e1e7ec;
    background-color: #e1e7ec;
    color: #999;
  }

  .step-icon-wrapper::before,
  .step-icon-wrapper::after {
    display: block;
    position: absolute;
    top: 50%;
    width: 50%;
    height: 3px;
    background-color: #e1e7ec;
    content: '';
    z-index: 1;
  }

  .step-icon-wrapper::after {
    right: 0;
  }

  .step.completed .step-icon {
    border-color: #007bff;
    background-color: #007bff;
    color: #fff;
  }

  .step.completed .step-icon-wrapper::before,
  .step.completed .step-icon-wrapper::after {
    background-color: #007bff;
  }

  @media screen and (min-width: 992px) {
    .step-title {
      display: block;
      font-size: 1.3em;
    }
  }

  //
  // Extras
  // - wrapper class 'shadow'

  .steps.inset-shadow .step-icon {
    box-shadow: inset 0px 2px 3px rgb(0 0 0 / 25%);
  }
  .steps.inset-shadow .step-icon-wrapper::before,
  .steps.inset-shadow .step-icon-wrapper::after {
    box-shadow: inset 0px 1px 2px rgb(0 0 0 / 25%);
  }

</style>