<template>
  <b-form-group v-slot="{ ariaDescribedby }" class="mb-0">
    <b-form-checkbox
      v-model="checked"
      switch
      size="md"
      :disabled="disabled"
      :aria-describedby="ariaDescribedby"
      v-on:change="expressSubmit"
    >{{ label }}</b-form-checkbox>
  </b-form-group>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'JobExpressSwitch',
  
  data() {
    return {
      checked: false,
    }
  },

  props: {
    job: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: false,
      default: ''
    },
    value: {
      type: Number, // Only parsed into checked as a boolean
      default: 0
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.checked = this.value ? true : false;
  },

  methods: {
    // ORDER MODULE
    ...mapActions('job', [
      'updateJobExpressPromise',
    ]),

    //updateValue: function (value) {
    updateValue: function (value) {
      this.$emit('input', value ? 1 : 0);
    },

    // Submit an express switching thingymojang
    async expressSubmit() {
      let confirm_text = 'Are you sure that you want to change express setting for this job?';

      this.$confirmIt(confirm_text)
      .then(value => {
        if (value) {
          
          // Setup data
          let data = {};
          data[this.type] = this.checked;

          // Post
          this.updateJobExpressPromise({id: this.job, data: data})
          .then((result) => {
            const message = result.data.message ?? ( result.data.status == 'ok' ? 'Save successful' : 'Unknown error' );
            const variant = result.data.status == 'ok' ? 'success' : 'danger';
            this.$toastIt(message, {variant: variant, autoHideDelay: 2000});

            // Revert (?)
            if ( result?.data?.status !== 'ok' ) {
              this.checked = !this.checked;
            }
            // Done
          });
        }

        // Revert (?)
        if ( !value ) {
          this.checked = !this.checked;
        }

        // Emit update
        this.updateValue(this.checked);
      });
    },    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>
