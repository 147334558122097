<template>
  <div class="warnings-container">
    <div v-if="warnings.length" class="warnings mb-4">
      <template v-for="(item, key) in warnings">
        <span class="order-warning mb-2" :key="`warning-${item.id}-${key}`">
          <!-- <strong>{{ item.raised_at | moment("Y-MM-DD") }}</strong> - -->
          <!--<b-icon icon="exclamation-triangle"></b-icon>--> {{ item.text }}
        </span>
      </template>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

//import OrderPreset from './OrderPreset.vue';

export default {

  name: 'Warnings',

  components: {
    //OrderInclude,
  },


  props: [
    'warnings',
  ],

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters([
      'timestampAsDate'
    ]),

    warningLines() {

      let output = [];
      //const shipments = JSON.parse(JSON.stringify(this.shipments));

      return output;
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .warnings {

    .XXXorder-warning {
      display: inline-block;
      border: 1px solid #721c24;
      padding: 0.2em 1em;
      border-radius: 999px;
      background: #f9a6ae;
      color: #721c24;

      svg {
        color: #721c24;
      }

      &:not(:last-child) {
        margin-right: 0.5em;
      }
    }

    .order-warning {
      padding: 1em;
      display: block;
      color: white;
      opacity: 0.83;
      transition: opacity 0.6s;
      margin-bottom: 1em;
      background: #f44336;
      color: #ffffff;
    }
  }

  @media screen and (min-width: 992px) {
    .warnings {
      text-align: left;
    }
  }

</style>