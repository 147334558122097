<template>
  <div class="job-details">

    <!-- Loading -->
    <div v-if="loading && !error" class="container loading">
      <b-card>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </b-card>
    </div>

    <!-- Page Error -->
    <div v-if="error" class="container error mt-5">
      <b-alert show dismissible variant="danger">
        <span v-html="error"></span>
      </b-alert>
      <a href="#" @click="$router.go(-1)">Go back</a>
    </div>

    <!-- JOB LOADED -->
    <div v-if="!loading && job" class="content">

      <!-- <TicketCreate :job_id="job.id" /> -->
      <ClaimCreate :job="job" />

      <!-- grey intro -->
      <div class="bg-site-light pt-4 mb-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div
                class="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-end py-2 text-center text-sm-left">
                <div class="flex-sm-fill">
                  <h2 class="mb-0">
                    <span class="font-weight-light mr-2">
                      <b-icon class="text-success" icon="check2-circle" v-if="job.status >= 100"
                        v-b-tooltip.hover.right="printerapiStatusName(job.status)" />
                      <b-icon class="text-warning" icon="check2-circle" v-else-if="job.status < 10"
                        v-b-tooltip.hover.right="printerapiStatusName(job.status)" />
                      <b-icon class="text-success" icon="check2-circle" v-else-if="job.status < 80"
                        v-b-tooltip.hover.right="printerapiStatusName(job.status)" />
                      <b-icon class="text-danger" icon="x-circle" v-else
                        v-b-tooltip.hover.right="printerapiStatusName(job.status)" />
                    </span>
                    <span class="text-secondary">{{ job.jobid }}</span>
                  </h2>
                  <h5 class="mr-auto">
                    <b-icon icon="hash" /> {{ job.name }}
                  </h5>
                </div>
                <div class="mt-3 mt-sm-0 ml-sm-3 flex-shrink-0">
                  <b-dropdown variant="outline-secondary" class="ml-1 pb-2" size="sm" right text="Actions"
                    aria-label="Order actions toolbar">
                    <b-dropdown-item v-on:click="cancelJob" class="mx-1" aria-label="Cancel job"
                      :disabled="!canJobBe('cancelled')">Cancel Job</b-dropdown-item>

                    <b-dropdown-item v-if="canJobBe('unlock_id_possible')" v-on:click="unlockOrderId" class="mx-1"
                      aria-label="Unlock Order ID">Unlock Order ID</b-dropdown-item>
                    <b-dropdown-item v-if="canJobBe('relock_id_possible')" v-on:click="relockOrderId" class="mx-1"
                      aria-label="Relock Order ID">Relock Order ID</b-dropdown-item>

                    <b-dropdown-item v-on:click="markJobForReturn({reason: 'customer'})" class="mx-1"
                      aria-label="Create Customer Return" :disabled="!canJobBe('returned')">Create Customer
                      Return</b-dropdown-item>
                    <b-dropdown-item v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])"
                      v-on:click="markJobForReturn({reason: 'bounced'})" class="mx-1"
                      aria-label="Register Bounced Return" :disabled="!canJobBe('returned')">Register Bounced
                      Return</b-dropdown-item>
                    <!-- <b-dropdown-item v-on:click="printReturnLabel({action: 'send_shipping_label'})" class="mx-1" aria-label="Send customer return label" v-if="canJobBe('return_printed')">Send customer return label</b-dropdown-item> -->

                    <b-dropdown-item v-on:click="pauseJob" class="mx-1" aria-label="Pause job"
                      v-if="canJobBe('customer-paused')">Pause Job</b-dropdown-item>
                    <b-dropdown-item v-on:click="unpauseJob" class="mx-1" aria-label="Un-Pause job"
                      v-if="canJobBe('customer-un-paused')">Un-pause Job</b-dropdown-item>
                    <!-- <b-dropdown-item v-on:click="restartJob" class="mx-1" aria-label="Restart job" disabled>Restart Job</b-dropdown-item> -->
                    <!-- <b-dropdown-item v-on:click="createTicketQuestion" class="mx-1" aria-label="Create ticket" disabled>Create Ticket</b-dropdown-item> -->
                    <b-dropdown-item v-on:click="createClaimQuestion" class="mx-1" aria-label="Create claim"
                      :disabled="!canJobBe('claimed')">Create Claim</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Grid Wrapper / Layout -->
      <div class="details-wrapper container bg-site-light py-4 rounded">

        <!-- GRID-AREA: INTRO -->
        <div class="details-intro">

          <!-- STEPS / PROGRESS BALLS -->
          <div class="order-steps row">
            <div class="w-100 mb-4">
              <JobSteps class="inset-shadow" :status="job.status || 'Unknown'"
                v-if="job.status < 80 || job.status >= 100" />
              <!-- fallback to a warning message when job is deleted or cancelled... -->
              <div v-else class="col-sm-12">
                <b-alert show variant="danger" class="site-bg">
                  <h5 class="mt-2">
                    Job status: <strong>{{ printerapiStatusName(job.status) }}</strong>
                    <!-- <b-icon icon="exclamation-triangle-fill" class="text-warning h4 mb-0"/> -->
                  </h5>
                  <p v-if="canJobBe('relock_id_possible')" class="text-dark mb-0">
                    <b-icon icon="unlock-fill"></b-icon> <i>Order is unlocked and may be replaced by integration.</i>
                  </p>
                </b-alert>
              </div>
              <!-- job is unlocked for replacement -->
            </div>
          </div><!-- end STEPS -->

          <!-- JOB NOTICE -->
          <div v-if="showStatusBox" class="row">
            <div class="col-md-12 col-sm-12">
              <b-overlay :show="false" rounded="sm">
                <b-card no-body border-variant="light" align="left" class="">
                  <b-card-header header-border-variant="secondary" header-bg-variant="white" header-text-variant="dark"
                    class="d-flex justify-content-between">
                    <span>STATUS</span>
                  </b-card-header>

                  <b-card-body class="pt-3">
                    <div v-if="job.fulldata.warnings.length">
                      <p class="mb-2 XX-d-flex XX-align-items-center">
                        <!-- <span class="mr-2"><b-icon icon="exclamation-triangle-fill" class="text-warning h2 mb-0"></b-icon></span> -->
                        <b>Warning!</b> <span>Order contain {{ job.fulldata.warnings.length }} issues that need to be
                          resolved!</span>
                      </p>
                      <Warnings :warnings="job.fulldata.warnings" />
                    </div>

                    <!-- <TicketsSimple :tickets="job.tickets" /> -->
                  </b-card-body>
                </b-card>
              </b-overlay>
            </div>
          </div><!-- end ORDER NOTICE -->
        </div><!-- intro -->


        <!-- GRID-AREA: CONTENT -->
        <div class="details-content">
          <div v-if="job.fulldata.shipping.length" class="row">
            <div class="col-sm-12">
              <div class="mb-3">
                <b-overlay :show="false" rounded="sm">
                  <b-card border-variant="light" header="SHIPMENTS" header-border-variant="secondary"
                    header-bg-variant="white" header-text-variant="dark" align="left" class="">
                    <b-card-text>
                      <div class="details-content">
                        <Shipments :shipments="job.fulldata.shipping" />
                      </div>

                      <!-- shipment costs -->
                      <div v-if="costs.shipment_costs.length" class="mt-3">
                        <div>
                          <b-button size="sm" variant="outline-dark" v-b-toggle.collapseCosts-1>Show Costs</b-button>
                        </div>

                        <b-collapse id="collapseCosts-1" class="collapse mt-4">
                          <table class="table table-sm mb-0">
                            <thead>
                              <tr>
                                <th scope="col">Title</th>
                                <th scope="col" class="text-right">Amount</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr v-for="cost in costs.shipment_costs" :key="cost.id">
                                <td>{{ cost.title }}<b-badge v-if="cost.status !='handled'" pill class="ml-2"
                                    variant="secondary">Pending</b-badge></td>
                                <td class="text-right">{{ cost.invoice_cost.toFixed(2) }} SEK</td>
                              </tr>
                              <tr>
                                <th>Total</th>
                                <th class="text-right">{{ shipmentCostTotal.toFixed(2) }} SEK</th>
                              </tr>
                            </tbody>
                          </table>
                        </b-collapse>

                      </div>

                    </b-card-text>
                  </b-card>
                </b-overlay>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <!-- api details-content -->
              <div class="mb-3">
                <b-overlay :show="false" rounded="sm">
                  <b-card border-variant="light" header="JOB CONTENT" header-border-variant="secondary"
                    header-bg-variant="white" header-text-variant="dark" align="left" class="">
                    <b-card-text>
                      <div class="details-content">
                        <JobContent :items="job.fulldata.items" v-if="job.fulldata.items" />
                      </div>
                      <div class="text-right text-md-left mt-4"><small class="text-muted"><i>Art thumbnails are not
                            available in early stages of production.</i></small></div>
                    </b-card-text>
                  </b-card>
                </b-overlay>
              </div>
            </div>
          </div>
        </div>

        <!-- GRID-AREA: RIGHT SIDE -->
        <div class="details-side">
          <!-- DELIVERY DETAILS -->
          <div class="row">
            <div class="col-sm-12">
              <b-overlay :show="hasPendingRequests(['address', 'priority'])" rounded="sm">
                <b-card border-variant="light" header="DELIVERY" header-border-variant="secondary"
                  header-bg-variant="white" header-text-variant="dark" align="left" class="">
                  <b-card-text>
                    <JobExpressSwitch :job="this.job_id" :disabled="!canJobBe('edited')" type="production"
                      label="Express Production" v-model="job.fulldata.express_production" />
                    <JobExpressSwitch :job="this.job_id" :disabled="!canJobBe('edited')" type="delivery"
                      label="Express Delivery" v-model="job.fulldata.express_delivery" />
                    <!--
                    <p class="mb-0">
                      <b-badge variant="dark" v-if="job.fulldata.tags.includes('b2b')">B2B</b-badge>
                    </p>
                    -->
                    <hr class="my-2">
                    <JobAddressInline :address="job.fulldata.delivery_address" :id="job_id"
                      :editable="canJobBe('edited')" @save="saveJobAddress" />
                  </b-card-text>
                </b-card>
              </b-overlay>
            </div>
          </div>


          <!-- return mark -->
          <div class="row mt-4" v-if="markedForReturn">
            <div class="col-sm-12">
              <b-card border-variant="danger" header="RETURN" header-border-variant="danger" header-bg-variant="danger"
                header-text-variant="light" align="left" class="">
                <b-card-text>
                  <b-row no-gutters>
                    <template>
                      <b-col cols="4" class="text-right"><strong class="">Status</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ inReturnStatus() }}</b-col>

                      <b-col cols="4" class="text-right"><strong class="">Type</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ inReturnType() }}</b-col>
                    </template>

                    <!--                <template>
                      <b-col cols="4"></b-col>
                      <b-col cols="8">
                        <b-button
                          v-if="canJobBe('return_printed')"
                          v-on:click="printReturnLabel({action: 'send_shipping_label'})"
                          aria-label="Send new return label"
                          variant="outline-danger"
                          size="sm"
                        >Send new return label</b-button>                    
                      </b-col>
                    </template>
-->
                  </b-row>
                </b-card-text>
              </b-card>
            </div>
          </div>

          <!-- INFO -->
          <div class="row mt-4">
            <div class="col-sm-12">
              <b-card border-variant="light" header="INFORMATION" header-border-variant="secondary"
                header-bg-variant="white" header-text-variant="dark" align="left" class="">
                <b-card-text>
                  <b-row no-gutters>

                    <!-- display account for supers -->
                    <template v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                      <b-col cols="4" class="text-right"><strong class="">Account</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.account }}</b-col>
                    </template>

                    <!-- display created at -->
                    <b-col cols="4" class="text-right"><strong class="">Created</strong>:</b-col>
                    <b-col cols="8" class="pl-2">{{ job.created_at | moment("Y-MM-DD HH:mm") }}</b-col>

                    <!-- display status, better later -->
                    <template>
                      <b-col cols="4" class="text-right"><strong class="">Status</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ printerapiStatusName(job.status) }}</b-col>
                    </template>

                    <!-- api raw status if super -->
                    <template v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                      <b-col cols="4" class="text-right"><strong class="">API Status</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.fulldata.status }}</b-col>
                    </template>

                    <!-- api raw data_at if super -->
                    <template v-if="userHasAnyOfRoles(['superadmin','puadmin', 'customerservice'])">
                      <b-col cols="4" class="text-right"><strong class="">API Data</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.fulldata_at | moment("Y-MM-DD HH:mm") }}</b-col>
                    </template>

                    <!-- job_type -->
                    <template v-if="job.fulldata.job_type">
                      <b-col cols="4" class="text-right"><strong class="">Type</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.fulldata.job_type }}</b-col>
                    </template>

                    <!-- display if it is deleted - dont know if this is the right flag -->
                    <template v-if="job.deleted">
                      <b-col cols="4" class="text-right"><strong class="text-danger">Deleted</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.deleted_at | moment("Y-MM-DD HH:mm") }}</b-col>
                    </template>

                    <!-- display if it is expired - dont know if this is the right flag -->
                    <template v-if="job.expired">
                      <b-col cols="4" class="text-right"><strong class="text-danger">Expired</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.expired_at | moment("Y-MM-DD HH:mm") }}</b-col>
                    </template>

                    <!-- if we have downloaded_at on the fulldata, display this (dont know what really this is) -->
                    <template v-if="job.fulldata.downloadedAt">
                      <b-col cols="4" class="text-right"><strong class="">Sourced</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.fulldata.downloadedAt | moment("from", "now") }}</b-col>
                    </template>

                    <!-- number of line items -->
                    <template>
                      <b-col cols="4" class="text-right"><strong class="">Line Items</strong>:</b-col>
                      <b-col cols="8" class="pl-2">{{ job.fulldata.items.length }}</b-col>
                    </template>

                    <!-- claims list - temporary until better? -->
                    <template v-if="job.claims && job.claims.length">
                      <b-col cols="4" class="text-right"><strong class="">Claim</strong>:</b-col>
                      <b-col cols="8" class="pl-2">
                        <div v-for="claim in job.claims" :key="`claim-${claim.id}`" class="mr-2">
                          <b-link :to="{name: 'individualClaim', params: {claim_id: claim.id}}"
                            :variant="claimVariant(claim.status)">
                            {{ claim.no }}
                          </b-link> / {{ claim.status }}
                        </div>
                      </b-col>
                    </template>

                  </b-row>
                </b-card-text>
              </b-card>
            </div>
          </div>

        </div>
      </div>

      <!-- <pre>{{ job }}</pre> -->
    </div><!-- end details-content -->

  </div><!-- end wrapper -->
</template>


<script>
import { mapGetters, mapActions } from 'vuex'

import JobAddressInline from '@/components/JobAddressInline.vue';
//import OrderContent from '@/components/OrderContent/OrderContent.vue';
import JobSteps from '@/components/JobSteps/JobSteps.vue';
import JobContent from '@/components/JobContent/JobContent.vue';
//import ItemOrderContent from '@/components/ItemOrderContent/ItemOrderContent.vue';
//import Chatter from '@/components/Chatter/Chatter.vue';
import JobExpressSwitch from '@/components/ExpressSwitch/JobExpressSwitch.vue';
import Shipments from '@/components/Shipments/Shipments.vue';
import Warnings from '@/components/Warnings/Warnings.vue';
//import Tickets from '@/components/Tickets/Tickets.vue';
//import TicketsSimple from '@/components/Tickets/TicketsSimple.vue';
//import TicketCreate from '@/components/TicketCreate/TicketCreate.vue';
import ClaimCreate from '@/components/ClaimCreate/ClaimCreate.vue';


export default {
  components: {
    JobAddressInline,
    JobContent,
    JobSteps,
    JobExpressSwitch,
    Shipments,
    Warnings,
    //TicketsSimple,
    //TicketCreate,
    ClaimCreate
  },

  data() {
    return {
      loading: true,  // Page loading
      error: false,   // Page Error
      job: null,      // Current order (loaded on full)
      costs: null,
    }
  },

  computed: {
    ...mapGetters([
      'hasPendingRequests',
    ]),

    // USER MODULE
    ...mapGetters('user', [
      'userHasRole',
      'userHasAnyOfRoles',
      'currentUser'
    ]),

    // JOB MODULE
    ...mapGetters('job', [
      'printerapiStatusName'
    ]),

    // Order ID from _PARAM_
    job_id() { 
      return this.$route.params.job_id;
    },

    showStatusBox() {
      if ( this.job.fulldata.warnings.length > 0 )
        return true;

      if ( this.job.tickets.length > 0 )
        return true;

      return false;
    },

    markedForReturn() {
      if ( ! this.job ) return false;
      if ( this.job?.return.find(x => x.id == 'marked_for_return') == undefined ) return false;
      return true;
    },

    // Total shipment_costs
    shipmentCostTotal: function () {
      const items = this.costs?.shipment_costs ?? [];
      let sum = 0;

      // calculate sum using forEach() method
      items.forEach(item => {
        sum += item.invoice_cost ?? 0;
      })

      return sum
    }

  },

  created () {
    this.fetchData();
  },

  methods: {
    // BASE
    ...mapActions([
      'addPendingRequest',
      'removePendingRequest',
    ]),

    // ORDER MODULE
    ...mapActions('job', [
      'getJobPromise',
      'cancelJobPromise',
      'customerPauseJobPromise',
      'restartJobPromise',
      'updateReturnJobPromise',
      'addJobFlagsPromise',
      'removeJobFlagsPromise',
    ]),

    // TICKET MODULE
    ...mapActions('ticket', [
      'createTicket',
    ]),

    // Save new address in component - when saved result comes back from address component
    saveJobAddress(address) {
      this.job.fulldata.delivery_address = address;
    },


    // FETCH CURRENT JOB (route id) - FRESH DATA
    fetchData() {
      // Reset all
      this.error = this.job = null;
      this.loading = true;

      this.getJobPromise(this.job_id)
      .then((result) => {
        if ( result.data.status === 'ok' ) {
          this.job = result.data.job;
          this.costs = result.data.costs ?? {};
          this.loading = false;
        }
        if ( result.data.status !== 'ok' ) {
          this.error = result.data.message || 'Could not load data!';
          this.$toastIt(this.error, {variant: 'danger', autoHideDelay: 2000});
        }
      })
      .catch((error) => {
        let msg = `Could not retrieve order details! (` + error.message + `)`;
        this.$toastIt(msg, {variant: 'danger', autoHideDelay: 2000});
        this.error = msg;
      });
    },

    // CANCEL current job
    cancelJob() {
      this.$confirmIt(`Are you sure that you want to cancel this job?`)
      .then(value => {
        if (value) {
          this.cancelJobPromise(this.job_id)
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Job cancelled!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not restart job!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },

    // PAUSE current job
    pauseJob() {
      this.$confirmIt(`Are you sure that you want to request a pause of this Job?`)
      .then(value => {
        if (value) {
          this.customerPauseJobPromise({id: this.job_id, status: true})
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Job paused!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not pause job!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },

    unpauseJob() {
      this.$confirmIt(`Are you sure that you want to un-pause this Job?`)
      .then(value => {
        if (value) {
          this.customerPauseJobPromise({id: this.job_id, status: false})
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Job un-paused!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not pause job!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },    

    // RESTART current job
    restartJob() {
      this.$toastIt('Access denied!', {variant: 'warning', autoHideDelay: 2000});
      /*
      this.$confirmIt(`Are you sure that you want to restart this order?`)
      .then(value => {
        if (value) {
          this.restartJobPromise(this.order_id)
          .then((result) => {
            console.log("RestartAnswer:", result);
            this.fetchData();
          });
        }
      });
      */
    },

    // CANCEL current job
    markJobForReturn( data ) {
      let question;
      let send_data = {};

      switch( data?.reason ) {
        case 'customer': {
          question = `Do you want to create a customer return for this order ? (no return shipping is currently provided)`; // For orders shipped within Sweden, a shipping label can be directly sent to your customer if you so choose.`;
          send_data = { marked_for_return: true, customer_return: true };
          break;
        }
        case 'bounced': {
          question = `Do you want to mark order as a bounced return?`;
          send_data = { marked_for_return: true, bounced_return: true, return_received: true };
          break;
        }
        default: {
          this.$toastIt(`Invalid return reason coded!`, {variant: 'danger', autoHideDelay: 2000});
          break;
        }
      }

      this.$confirmIt(question)
      .then(value => {
        if (value) {
          this.updateReturnJobPromise({id: this.job_id, data: send_data })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = result.data.message || 'Job marked with return!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              // Should we also ask for sending of label to customer? only when customer - can not count on data in store so small special check here... :(
              if ( data.reason == 'customer' && this.job?.fulldata?.delivery_address?.country == 'SE' ) {
                // this.printReturnLabel({action: 'send_shipping_label'});
              }
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not mark job for return!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },


    // CANCEL current job
    printReturnLabel( data ) {
      this.$confirmIt(`Do you want to send a return label to the customer?`)
      .then(value => {
        if (value) {
          this.updateReturnJobPromise({id: this.job_id, data: data })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = 'Shipping label will be sent to customer!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not update return information!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },    

    hasWarning(warning) {
      if (! this.job) return false;
      if (! this.job || ! this.job.fulldata || ! Array.isArray(this.job.fulldata.warnings)) return false;
      const matching_tags = this.job.fulldata.warnings.filter(x => x.flag == warning && x.active);
      
      return matching_tags.length > 0;
    },
    
    // Should maybe come from the inside when we are done...!!!
    canJobBe( what ) {
      switch ( what ) {
        case 'edited': {
          if ( ! this.job ) return false;
          if ( this.job.status >= 90 ) return false;
          return true;
        }
        case 'customer-paused': {
          if ( ! this.job ) return false;
          if ( this.job.status >= 19 ) return false;
          if ( this.hasWarning('customer_paused') ) return false;
          return true;
        }
        case 'customer-un-paused': {
          if ( ! this.job ) return false;
          if ( this.job.status >= 19 ) return false;
          if ( ! this.hasWarning('customer_paused') ) return false;
          return true;
        }
        case 'cancelled': {
          if ( ! this.job ) return false;
          if ( this.job.status > 10 ) return false;
          return true;
        }
        case 'claimed': {
          if ( ! this.job ) return false;
          if ( this.job.status < 100 ) return false;
          // expired or some other stuff we dont really have acces to?!?!?!?!
          if ( this.job?.fulldata?.delivery_address?.address1 == '(expired)' ) return false;
          // already a claim
          if ( this.job.jobid.startsWith("claim-") ) return false;
          return true;
        }
        case 'returned': {
          if ( ! this.job ) return false;
          if ( this.job?.status != 100 ) return false;
          // already marked
          const has_mark = this.job?.return && this.job?.return.find(x => x.id == 'marked_for_return');
          if ( has_mark != undefined ) return false;
          // expired or some other stuff we dont really have acces to?!?!?!?!
          if ( this.job?.fulldata?.delivery_address?.address1 == '(expired)' ) return false;
          return true;
        }
        case 'return_printed': {
          if ( ! this.job ) return false;
          if ( this.job?.status != 100 ) return false;
          // already marked
          const has_mark = this.job?.return && this.job?.return.find(x => x.id == 'marked_for_return');
          if ( has_mark == undefined ) return false;
          // already returned
          const has_returned = this.job?.return && this.job?.return.find(x => x.id == 'return_received');
          if ( has_returned != undefined ) return false;
          // not a customer return
          const customer_return = this.job?.return && this.job?.return.find(x => x.id == 'customer_return');
          if ( customer_return == undefined ) return false;
          // check countries
          if ( ! this.job?.fulldata?.delivery_address?.country == 'SE' ) return false;

          return true;
        }
        case 'unlock_id_possible': {
          if (! this.job || !this.job.status) return false;
          if (this.job?.flags?.includes('+replace')) return false;
          if (this.job.status < 97 || this.job.status > 99) return false;
          return true;
        }
        case 'relock_id_possible': {
          if (! this.job || !this.job.status) return false;
          if (! this.job?.flags?.includes('+replace')) return false;
          if (this.job.status < 97 || this.job.status > 99) return false;
          return true;
        }
      }

      return false;
    },

    // Tickets
    createTicketQuestion() {
      this.$bvModal.show('modal-ticket-create');
    },

    // Claims
    createClaimQuestion() {
      this.$bvModal.show('modal-claim-create');
    },

    claimVariant(status) { // Quick'n'Dirty test
      switch (status) {
        case 'open': return 'success';
        case 'rejected': return 'danger';
        case 'pending': return 'warning';
      }
      return 'secondary';
    },

    // In a return status, give name of status, else empty string.
    inReturnStatus() {
      if ( ! this?.job?.return ) return false;
      const is_created = this.job?.return && this.job.return.filter( x => x.id == 'marked_for_return' ).length;
      const is_returned = this.job?.return && this.job.return.filter( x => x.id == 'return_received' ).length;
      if ( is_created && ! is_returned ) return "Pending";
      if ( is_created && is_returned ) return "Returned";
      return "";
    },
    inReturnType() {
      if ( ! this?.job?.return ) return "";
      const by_customer = this.job.return.filter( x => x.id == 'customer_return' ).length;
      if ( by_customer ) return "Customer"
      return "Bounced";
    },
    returnTagTime(tagname) {
      const tag = this.job?.return.find( x => x.id == tagname );
      if ( tag?.at ) return tag.at;
      return undefined;
    },

    unlockOrderId() {
      this.$confirmIt(`Do you want want to unlock this order ID (order may be replaced)?`)
      .then(value => {
        if (value) {
          this.addJobFlagsPromise({id: this.job_id, data: {flags: ['+replace']} })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = 'Order updated!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not update order!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    },

    relockOrderId() {
      this.$confirmIt(`Do you want want to relock this order ID (order may not be replaced)?`)
      .then(value => {
        if (value) {
          this.removeJobFlagsPromise({id: this.job_id, data: {flags: ['+replace']} })
          .then((result) => {
            if (result.data.status == 'ok') {
              const message = 'Order updated!';
              this.$toastIt(message, {variant: 'info', autoHideDelay: 2000});
              this.fetchData();
            }
            if (result.data.status !== 'ok') {
              const message = result.data.message || 'Could not update order!';
              this.$toastIt(message, {variant: 'danger', autoHideDelay: 2000});
            }
          });
        }
      });
    }    


  },

}
</script>

<style lang="scss">
  .alert-danger.style-2 {
    color: #721c24;
    background-color: #f9a6ae;
    border-color: #721c24;
    border-width: 2px;
  }
</style>

<style lang="scss" scoped>
  // --------------------
  // Layout - NEEDS NEW CONTAINER ETC!!!
  // --------------------
  .details-intro {
    grid-area: in;
  }
  .details-content {
    grid-area: main;
  }
  .details-side {
    grid-area: side;
  }

  @media (min-width: 422px) {
    .details-wrapper {
      display: grid;
      gap: 1em;
      grid-auto-rows: minmax(56px, auto);
      grid-template-columns: 1fr;
      grid-template-areas:
        "in"
        "main"
        "side";
    }
  }

  @media (min-width: 1140px) {
    .details-wrapper {
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      grid-auto-rows: minmax(56px, auto);
      grid-template-areas:
        "in in in in in in in in in"
        "main main main main main main side side side";
    }
  }
</style>