<template>

  <div class="address-details">

    <!-- DISPLAY PART -->
    <div class="address-grid" :class="{edit: edit_mode}">
      <div>Address 1:</div>
      <div>
        <span class="show">{{ display_address.address1 }}</span>
        <span class="edit">
          <b-form-input
            ref="address1"
            v-model="modal_address.address1"
            :state="field_state('address1')"
            trim
            aria-describedby="address-feedback-address1"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('address1')" id="address-feedback-address1">
            {{ field_message('address1') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Address 2:</div>
      <div>
        <span class="show">{{ display_address.address2 }}</span>
        <span class="edit">
          <b-form-input
            ref="address2"
            v-model="modal_address.address2"
            :state="field_state('address2')"
            trim
            aria-describedby="address-feedback-address2"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('address2')" id="address-feedback-address2">
            {{ field_message('address2') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Address 3:</div>
      <div>
        <span class="show">{{ display_address.address3 }}</span>
        <span class="edit">
          <b-form-input
            ref="address3"
            v-model="modal_address.address3"
            :state="field_state('address3')"
            trim
            aria-describedby="address-feedback-address3"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('address3')" id="address-feedback-address3">
            {{ field_message('address3') }}
          </b-form-invalid-feedback>
        </span>
      </div>

      <div>Contact:</div>
      <div>
        <span class="show">{{ display_address.contact }}</span>
        <span class="edit">
          <b-form-input
            ref="contact"
            v-model="modal_address.contact"
            :state="field_state('contact')"
            trim
            aria-describedby="address-feedback-contact"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('contact')" id="address-feedback-contact">
            {{ field_message('contact') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Zipcode:</div>
      <div>
        <span class="show">{{ display_address.zipcode }}</span>
        <span class="edit">
          <b-form-input
            ref="zipcode"
            v-model="modal_address.zipcode"
            :state="field_state('zipcode')"
            trim
            aria-describedby="address-feedback-zipcode"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('zipcode')" id="address-feedback-zipcode">
            {{ field_message('zipcode') }}
          </b-form-invalid-feedback>
        </span>
      </div>

      <div>City:</div>
      <div>
        <span class="show">{{ display_address.city }}</span>
        <span class="edit">
          <b-form-input
            ref="city"
            v-model="modal_address.city"
            :state="field_state('city')"
            trim
            aria-describedby="address-feedback-city"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('city')" id="address-feedback-city">
            {{ field_message('city') }}
          </b-form-invalid-feedback>
        </span>
      </div>

      <div>State:</div>
      <div>
        <span class="show">{{ display_address.state }}</span>
        <span class="edit">
          <b-form-input
            ref="state"
            v-model="modal_address.state"
            :state="field_state('state')"
            trim
            aria-describedby="address-feedback-state"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('state')" id="address-feedback-state">
            {{ field_message('state') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Country:</div>
      <div>
        <span class="show">{{ countryByCode(display_address.country) }}</span>
        <span class="edit">
          <b-form-select
            v-model="modal_address.country"
            :options="availableDeliveryCountries"
            text-field="name"
            value-field="id"
            :state="field_state('country')"
            trim
            aria-describedby="address-feedback-country"
          ></b-form-select>
          <b-form-invalid-feedback :state="field_state('country')" id="address-feedback-country">
            {{ field_message('country') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Mobile:</div>
      <div>
        <span class="show">{{ display_address.mobile }}</span>
        <span class="edit">
          <b-form-input
            ref="mobile"
            v-model="modal_address.mobile"
            :state="field_state('mobile')"
            trim
            aria-describedby="address-feedback-mobile"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('mobile')" id="address-feedback-mobile">
            {{ field_message('mobile') }}
          </b-form-invalid-feedback>
        </span>
      </div>
      
      <div>Email:</div>
      <div>
        <span class="show">{{ display_address.email }}</span>
        <span class="edit">
          <b-form-input
            ref="email"
            v-model="modal_address.email"
            :state="field_state('email')"
            trim
            aria-describedby="address-feedback-email"
          ></b-form-input>
          <b-form-invalid-feedback :state="field_state('email')" id="address-feedback-email">
            {{ field_message('email') }}
          </b-form-invalid-feedback>
        </span>
      </div>

      <template v-if="editable">
        <div><!-- left column -->
          <b-button
            id="edit-address-button"
            size="sm"
            variant="default"
            v-on:click="startEdit()"
            v-show="!edit_mode && is_editable"
            tabindex="0"
          >
            <b-icon icon="pencil"></b-icon>
          </b-button>
          <b-tooltip v-if="!edit_mode" target="edit-address-button">Edit delivery address</b-tooltip>

        </div>
        <div class="text-right pt-2"><!-- right column -->
          <b-button
            id="save-address-button"
            size="sm"
            variant="success"
            v-on:click="submitData()"
            v-show="edit_mode"
            tabindex="0"
            class="mr-2"
          >
            <b-icon icon="check-circle"></b-icon> Submit
          </b-button>
          <b-button
            id="cancel-address-button"
            size="sm"
            variant="danger"
            v-on:click="cancelData()"
            v-show="edit_mode"
            tabindex="0"
          >
            <b-icon icon="x-circle"></b-icon> Cancel
          </b-button>
        </div>
      </template>
    </div>

  </div>


</template>


<script>
import { mapGetters, mapActions } from 'vuex'

export default {

  name: 'JobAddressInline',

  props: [
    'id',       // Current job id (so we can save)!!!
    'editable', // Can be switched to edit mode?
    'address',  // Assigned to modal_address when modal is used to edit "onShow"!
  ],

  data() {
    return {
      modal_address: {},          // Address we are working with in the modal
      default_display_address: {  // Default placeholder address
        address1: '', address2: '', address3: '', contact: '', zipcode: '', city: '', state: '', country: '', mobile: '', email: '',
      },

      edit_mode: false,
      failed_save: false,         // Have we tried to save at least once - used to see if form errors should be displayed or not
      form_errors: [],            // universal(?) field errors.
    }
  },

  computed: {
    ...mapGetters([
      'availableDeliveryCountries',
      'countryByCode'
    ]),

    // Address to display when not in "edit mode"
    display_address: function() {
      return this.address ?? this.default_display_address;
    },

    // Placeholder for when we can edit an address -or- not.
    is_editable: function() {
      return this.address;
    },

  },

  methods: {
    ...mapActions([
      'addPendingRequest',
      'removePendingRequest',
    ]),
    ...mapActions('job', [
      'updateJobAddressPromise',
    ]),

    startEdit() {
      this.modal_address = Object.assign({}, this.address); // Could be done on open (but experianced data flickering).
      this.edit_mode = true;
      this.$nextTick(() => { this.$refs.address1.focus(); });
    },

    // Resetting modal data and settings
    resetData() {
      this.modal_address = {};
      this.form_errors = [];
      this.failed_save = false;
    } ,

    // Resetting modal data and settings
    cancelData() {
      this.form_errors = [];
      this.failed_save = false;
      this.edit_mode = false;
    },

    // Handle the data submission to server and logic for errors and closing of the modal.
    submitData() {

      this.addPendingRequest('address');
      this.failed_save = false;

      // Call store function
      this.updateJobAddressPromise({id: this.id, data: this.modal_address}).then((result) => {
        // Flag "form" as validated with possible errors.
        this.form_errors = result.data.field_errors ?? [];

         // We got a response, now we check it.
        if ( result.data.status === 'success' ) {
          
          // Show that all went ok to user.
          this.$toastIt(`Address updated!`, {variant: 'success', autoHideDelay: 2000});

          // Cleaned up data... (no need if everything is ok since we are closing the modal)
          this.modal_address = result.data.address;

          // Send OK data back to parent (if needed) - -OR- it could come back from server?
          const send_item = Object.assign({}, this.modal_address); 
          this.$emit('save', send_item);

          this.edit_mode = false;
          this.failed_save = false;
         }
        else { // Handle errors
          // Flag that we have a failed save (to show errors)
          this.failed_save = true;
          
          //const message = this.form_errors.map(item => item.message).join("<br>") ?? result.data.message ?? 'Error saving!';
          //const message = this.form_errors[0].message ?? result.data.message ?? 'Error saving!';
          const message = result.data.message ?? 'Error saving!';
          this.$toastIt(message, {variant: 'danger', autoHideDelay: 5000});
        }
        
        this.removePendingRequest('address');
      })
      .catch((err) => {
        this.table.isBusy = false;
        this.removePendingRequest('address');
        this.$toastIt(`Error: ${err}`, {variant: 'danger', autoHideDelay: 5000});
      });

    },

    //
    // FORM ERROR HANDLING
    //

    // Fields validation status (checkbox, red cross)
    field_state( field_id ) {
      // Return undefined value when no unsuccessful save was done (no icons)
      if (!this.failed_save) {
        return undefined;
      }

      // Check if field is in error-list
      const field_error = this.form_errors.find(x => x.field === field_id);

      // Set validation to false if we find errors
      if (field_error) {
        return false;
      }

      // No greens - Fallbacks to undefined.
    },
    
    // - message for the error sent back from server.
    field_message( field_id ) {
      const field_error = this.form_errors.find(x => x.field === field_id);
      
      if (!field_error)
        return null;

      return field_error.message ?? '';
    }
  },
}
</script>

<style lang="scss" scoped>
  .address-grid {
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
  .address-grid div:nth-child(odd){
    text-align: right;
    font-weight: 600;
    padding-right: 15px;
  }

  .show {
    font-size: 0.9rem;
    line-height: 1.6em;
  }

  .address-grid {
    .show { display: block; }
    .edit { display: none; }
  }
  .address-grid.edit {
    .show { display: none; }
    .edit { display: block; }
  }

  input, select {
    border: 0;
    height: auto;
    font-size: 0.9rem;
    outline: 1px dotted #dadada;
    padding: 0px 8px;
    line-height: 1.6em;
  }

  input:focus, select:focus {
    color: #495057;
    background-color: #fff;
    border: 0;
    border-left: 4px solid #dc3545;
    padding: 0px 4px;
    outline: 1px dotted #dadada;
    box-shadow: none;
  }
</style>