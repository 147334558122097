<template>
  <div class="shipments-container">
    <div v-if="!shipments.length" class="loading" >
      <!-- <h5 class="font-weight-light">No Shipments</h5> -->
    </div>

    <div v-if="shipments.length" class="shipments">
      <b-table-simple small borderless stacked="md" responsive="sm" class="XXw-auto">
        <b-thead head-variant="white border-0">
          <b-tr>
            <b-th>Speditor</b-th>
            <b-th>Date</b-th>
            <b-th>Shipment No</b-th>
            <b-th>Parcel No</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, key) in shippingLines" :key="`shipment-${item.id}-${key}`">
            <b-td stacked-heading="Speditor" class="text-left">{{ item.speditor }}</b-td>
            <b-td stacked-heading="Date" class="text-left">{{ timestampAsDate(item.shipDate) }}</b-td>
            <b-td stacked-heading="Shipment No">
              <a :href="item.tracking_link" target="_blank" size="sm">{{ item.shipmentNo }}</a>
            </b-td>
            <b-td stacked-heading="Parcel No">
              <a :href="item.tracking_link" target="_blank" size="sm">{{ item.parcelNo }}</a>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
    </div>
  </div>
</template>


<script>
import { mapGetters } from 'vuex'

//import OrderPreset from './OrderPreset.vue';

export default {

  name: 'Shipments',

  components: {
    //OrderInclude,
  },


  props: [
    'shipments',
  ],

  data() {
    return {
    }
  },

  computed: {
    ...mapGetters([
      'timestampAsDate'
    ]),

    shippingLines() {
      let output = [];
      const shipments = JSON.parse(JSON.stringify(this.shipments));

      for (const shipment of shipments) {
        let added = false;
        for (const parcel of shipment.parcels) {
          let item = shipment;
          item.tracking_link = parcel?.tracking_link ?? shipment?.tracking_link ?? null;
          item.parcelNo = parcel?.parcelNo ?? null;
          delete(item.parcels);
          output.push(item);
          added = true;
        }

        if (!added) {
          output.push(shipment);
        }
      }
      return output;
    }
  },

  methods: {
  },
}
</script>

<style lang="scss" scoped>
  .shipments {
    
  }

  @media screen and (min-width: 992px) {
    .shipments {
    }
  }

</style>